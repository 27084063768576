import "matchmedia-polyfill";
import "matchmedia-polyfill/matchMedia.addListener";

import * as smoothScroll from "smooth-scroll";
smoothScroll('a[href*="#"]');

import "fetch-polyfill";

import "assets/css/global.scss";

import React from "react";
import { MatchMediaProvider } from "context";

export async function onClientEntry() {
   if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      console.log("RUN BROWSER API");
      require("smooth-scroll")('a[href*="#"]');
   }

   if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`);
   }

   // Object-fit/Object-position polyfill for gatsby-image (IE)
   const testImg = document.createElement(`img`);
   if (testImg.style.objectFit === `undefined` || testImg.style.objectPosition === `undefined`) {
      import(`object-fit-images`)();
      console.log(`Object-fit/Object-position are polyfilled`);
   }
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
   // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product-checkout
   if (location.search.includes("filters=")) {
      return false;
   }

   const isScrollingToSection = location.hash.trim() !== "";
   if (isScrollingToSection) {
      return true;
   }

   const currentPosition = getSavedScrollPosition(location);
   setTimeout(() => {
      window.scrollTo(...(currentPosition || [0, 0]));
   }, 0);

   return false;
};

export const wrapRootElement = ({ element }) => {
   return <MatchMediaProvider>{element}</MatchMediaProvider>;
};
